const stockReducer = (state = {
    stocks: []
  }, action) => {
    switch(action.type) {
      case "SHOW_STOCKS":
        return state = {
          ...state,
          stocks: action.payload
        };
      default:
        return state;
    }
  };

  export default stockReducer;
  