import React, { Component } from "react";
import $ from "jquery";

import "./Register.css";
//import DropDown from "./DropDown";
import {
  checkVAT,
  austria,
  belgium,
  bulgaria,
  croatia,
  cyprus,
  czechRepublic,
  denmark,
  estonia,
  finland,
  france,
  germany,
  greece,
  hungary,
  ireland,
  italy,
  latvia,
  lithuania,
  luxembourg,
  malta,
  netherlands,
  poland,
  portugal,
  romania,
  slovakiaRepublic,
  slovenia,
  spain,
  sweden,
  unitedKingdom,
  countries,
} from "jsvat";
export default class Register extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      mobile: "",
      name: "",
      password: "",
      error: "",
      contact_name: "",
      vat: "",
      confirmPass: "",
      success: "",
      country: "",
    };

    this.handlePassChange = this.handlePassChange.bind(this);
    this.handleconfirmPassChange = this.handleconfirmPassChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleMobileChange = this.handleMobileChange.bind(this);
    this.handleVatChange = this.handleVatChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissError = this.dismissError.bind(this);
    this.dismissSuccess = this.dismissSuccess.bind(this);
    this.register = this.register.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token") !== null) {
      const { history } = this.props;
      history.push("/home");
    }
  }

  dismissError() {
    this.setState({ error: "" });
  }

  dismissSuccess() {
    this.setState({ success: "" });
  }

  handleSubmit(evt) {
    evt.preventDefault();

    const {
      confirmPass,
      password,
      name,
      email,
      vat,
      country,
      contact_name,
      mobile,
    } = this.state;
    if (country === "") {
      return this.setState({ error: "Country code is required" });
    }

    if (password.length < 8) {
      return this.setState({
        error: "Password too short. Must have at least 8 characters.",
      });
    }

    if (confirmPass !== password) {
      return this.setState({ error: "Passwords are not identical" });
    }

    if (!name) {
      return this.setState({ error: "Name is required" });
    }

    if (!email) {
      return this.setState({ error: "Email is required" });
    }

    if (!password) {
      return this.setState({ error: "Password is required" });
    }

    if (!confirmPass) {
      return this.setState({ error: "Confirm Password is required" });
    }

    if (!vat) {
      return this.setState({ error: "VAT is required" });
    }

    if (!contact_name) {
      return this.setState({ error: "Contact name is required" });
    }

    const validVat = this.isVatValid(country, vat);
    if (!validVat) {
      return this.setState({ error: "VAT is invalid." });
    }

    this.register(email, password, name, mobile, country + vat, contact_name);
    return this.setState({ error: "" });
  }

  handleCompanyChange(evt) {
    this.setState({
      name: evt.target.value,
    });
  }

  handleEmailChange(evt) {
    this.setState({
      email: evt.target.value,
    });
  }

  handleMobileChange(evt) {
    this.setState({
      mobile: evt.target.value,
    });
  }

  handleVatChange(evt) {
    this.setState({
      vat: evt.target.value,
    });
  }

  handlePassChange(evt) {
    this.setState({
      password: evt.target.value,
    });
  }

  handleconfirmPassChange(evt) {
    this.setState({
      confirmPass: evt.target.value,
    });
  }

  handleContactChange(evt) {
    this.setState({
      contact_name: evt.target.value,
    });
  }

  handleCountryChange(evt) {
    this.setState({
      country: evt.target.value,
    });
  }

  getCountryVatValidation = (countryCode) => {
    switch (countryCode) {
      case "AT":
        return austria;
      case "BE":
        return belgium;
      case "BG":
        return bulgaria;
      case "HR":
        return croatia;
      case "CY":
        return cyprus;
      case "CZ":
        return czechRepublic;
      case "DK":
        return denmark;
      case "EE":
        return estonia;
      case "FI":
        return finland;
      case "FR":
        return france;
      case "DE":
        return germany;
      case "EL":
        return greece;
      case "HU":
        return hungary;
      case "IE":
        return ireland;
      case "IT":
        return italy;
      case "LV":
        return latvia;
      case "LT":
        return lithuania;
      case "LU":
        return luxembourg;
      case "MT":
        return malta;
      case "NL":
        return netherlands;
      case "PL":
        return poland;
      case "PT":
        return portugal;
      case "RO":
        return romania;
      case "SK":
        return slovakiaRepublic;
      case "SI":
        return slovenia;
      case "ES":
        return spain;
      case "SE":
        return sweden;
      case "GB":
        return unitedKingdom;
      default:
        return null;
    }
  };

  isVatValid = (countryCode, number) => {
    const country = this.getCountryVatValidation(countryCode);
    const vatValid = checkVAT(
      `${countryCode}${number}`,
      !!country ? [country] : countries
    );
    return vatValid.isValid && vatValid.isValidFormat;
  };

  async register(email, password, name, mobile, vat, contact_name) {
    await fetch(process.env.REACT_APP_SERVER_LINK + "user/signup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        name: name,
        mobile: mobile,
        vat: vat.trim(),
        contact_name: contact_name,
      }),
    }).then((response) => {
      if (response.ok)
        this.setState({
          success: "Successful registration. Waiting for admin approval.",
        });
      else {
        if (response.status === 409)
          this.setState({ error: "Supplier already registered." });
        else
          this.setState({
            error: "Something must be wrong. Please again try later.",
          });
      }
      return response.json();
    });
  }

  render() {
    // NOTE: I use data-attributes for easier E2E testing
    // but you don't need to target those (any css-selector will work)
    /* 
                    }*/
    const {
      name,
      vat,
      contact_name,
      password,
      email,
      confirmPass,
      error,
      success,
      country,
    } = this.state;

    var val = $("#mySelect").val();
    if (!!val)
      $("#mySelect").prepend(
        "<option value='" +
          val +
          "' data-value='selected' selected hidden>" +
          val +
          "</option>"
      );

    $("#mySelect").on("change", function () {
      var val = $("#mySelect").val();
      var lbl = $("#mySelect option:selected").text();

      $("#mySelect option[data-value='selected']").attr("value", val);
      $("#mySelect option[data-value='selected']").text(val);

      $("#mySelect").val(val);
    });

    $("#VAT").on("keypress", function (e) {
      if (e.which == 32) return false;
    });

    return (
      <div className="Register">
        <div className="linhaLogo2">
          <div className="linha3" />
          <div className="imgLogo">
            <a href="/">
              <svg viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#00204d"
                  d="M28 52.7C14.5 52.7 3.4 41.5 3.4 28c0-2.8.5-5.5 1.4-8.1l14.4 28c.2.4.4.7.9.7s.7-.3.9-.7L27.7 33c.1-.2.2-.3.3-.3.2 0 .2.2.3.3l6.8 14.9c.2.4.4.7.9.7s.7-.3.9-.7l14.4-28c.9 2.5 1.4 5.2 1.4 8.1-.1 13.5-11.2 24.7-24.7 24.7zm0-31.1c-.2 0-.2-.2-.3-.3L20 4.7c2.5-.9 5.2-1.4 8-1.4s5.5.5 8 1.4l-7.7 16.6c-.1.1-.1.3-.3.3zm-8.1 18c-.2 0-.2-.2-.3-.3L7 15.1c2.2-3.6 5.4-6.6 9.1-8.7L25 25.6c.2.4.4.5.7.5h4.6c.3 0 .5-.1.7-.5l8.8-19.2c3.7 2.1 6.9 5.1 9.1 8.7L36.4 39.3c-.1.2-.2.3-.3.3-.2 0-.2-.2-.3-.3L31 28.7c-.2-.4-.4-.5-.7-.5h-4.6c-.3 0-.5.1-.7.5l-4.8 10.6c-.1.1-.1.3-.3.3zM28 56c15.5 0 28-12.5 28-28S43.5 0 28 0 0 12.5 0 28s12.5 28 28 28z"
                ></path>
              </svg>
            </a>
          </div>
          <div className="linha4" />
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="title">
            <div className="title1"> Register </div>
            <div className="title2"> your company </div>
          </div>
          <div className="xtable">
            <div className="xrow">
              <div className="xcell">
                <input
                  type="text"
                  placeholder="Company name"
                  data-test="company name"
                  value={name}
                  onChange={this.handleCompanyChange}
                  className="inputs"
                />
              </div>
              <div className="xcell">
                <div className="vatField">
                  {/*
                  <DropDown />
                  */}
                  <select
                    className="selectField"
                    onChange={this.handleCountryChange}
                    defaultValue=""
                    required
                    id="mySelect"
                  >
                    <option value="" disabled hidden>
                      Country
                    </option>

                    <option value={"AT"} selected={country === "AT"}>
                      Austria
                    </option>
                    <option value={"BE"} selected={country === "BE"}>
                      Belgium
                    </option>
                    <option value={"BG"} selected={country === "BG"}>
                      Bulgaria
                    </option>
                    <option value={"HR"} selected={country === "HR"}>
                      Croatia
                    </option>
                    <option value={"CY"} selected={country === "CY"}>
                      Cyprus
                    </option>
                    <option value={"CZ"} selected={country === "CZ"}>
                      Czech Republic
                    </option>
                    <option value={"DK"} selected={country === "DK"}>
                      Denmark
                    </option>
                    <option value={"EE"} selected={country === "EE"}>
                      Estonia
                    </option>
                    <option value={"FI"} selected={country === "FI"}>
                      Finland
                    </option>
                    <option value={"FR"} selected={country === "FR"}>
                      France
                    </option>
                    <option value={"DE"} selected={country === "DE"}>
                      Germany
                    </option>
                    <option value={"EL"} selected={country === "EL"}>
                      Greece
                    </option>
                    <option value={"HU"} selected={country === "HU"}>
                      Hungary
                    </option>
                    <option value={"IE"} selected={country === "IE"}>
                      Ireland
                    </option>
                    <option value={"IT"} selected={country === "IT"}>
                      Italy
                    </option>
                    <option value={"LV"} selected={country === "LV"}>
                      Latvia
                    </option>
                    <option value={"LT"} selected={country === "LT"}>
                      Lithuania
                    </option>
                    <option value={"LU"} selected={country === "LU"}>
                      Luxembourg
                    </option>
                    <option value={"MT"} selected={country === "MT"}>
                      Malta
                    </option>
                    <option value={"NL"} selected={country === "NL"}>
                      Netherlands
                    </option>
                    <option value={"PL"} selected={country === "PL"}>
                      Poland
                    </option>
                    <option value={"PT"} selected={country === "PT"}>
                      Portugal
                    </option>
                    <option value={"RO"} selected={country === "RO"}>
                      Romania
                    </option>
                    <option value={"SK"} selected={country === "SK"}>
                      Slovakia
                    </option>
                    <option value={"SI"} selected={country === "SI"}>
                      Slovenia
                    </option>
                    <option value={"ES"} selected={country === "ES"}>
                      Spain
                    </option>
                    <option value={"SE"} selected={country === "SE"}>
                      Sweden
                    </option>
                    <option value={"GB"} selected={country === "GB"}>
                      United Kingdom
                    </option>
                  </select>
                  <input
                    type="text"
                    id="VAT"
                    placeholder="VAT"
                    data-test="vat"
                    value={vat}
                    onChange={this.handleVatChange}
                  />
                </div>
              </div>
            </div>
            <div className="xrow">
              <div className="xcell">
                <input
                  type="text"
                  placeholder="Contact name"
                  data-test="name"
                  value={contact_name}
                  onChange={this.handleContactChange}
                  className="inputs"
                />
              </div>
              <div className="xcell">
                <input
                  type="password"
                  placeholder="Password"
                  data-test="password"
                  value={password}
                  onChange={this.handlePassChange}
                  className="inputs"
                />
              </div>
            </div>
            <div className="xrow">
              <div className="xcell">
                <input
                  type="text"
                  id="Email"
                  placeholder="Email"
                  data-test="email"
                  value={email}
                  onChange={this.handleEmailChange}
                  className="inputs"
                />
              </div>
              <div className="xcell">
                <input
                  type="password"
                  placeholder="Confirm password"
                  data-test="confirm password"
                  value={confirmPass}
                  onChange={this.handleconfirmPassChange}
                  className="inputs"
                />
              </div>
            </div>
          </div>
          {/*
          <div className="info1">
            <div className="registerDivs">
              <input
                type="text"
                placeholder="Company name"
                data-test="company name"
                value={name}
                onChange={this.handleCompanyChange}
                className="inputs"
              />
            </div>

            <div className="registerDivs">
              <div className="vatField">
                <DropDown />
                <input
                  type="text"
                  id="VAT"
                  placeholder="VAT"
                  data-test="vat"
                  value={vat}
                  onChange={this.handleVatChange}
                />
              </div>
            </div>
          </div>
          <div className="info2">
            <div className="registerDivs">
              <input
                type="text"
                placeholder="Contact name"
                data-test="name"
                value={contact_name}
                onChange={this.handleContactChange}
                className="inputs"
              />
            </div>
            <div className="registerDivs">
              <input
                type="text"
                id="Email"
                placeholder="Email"
                data-test="email"
                value={email}
                onChange={this.handleEmailChange}
                className="inputs"
              />
            </div>
          </div>
          <div className="info2">
            <div className="registerDivs">
              <input
                type="password"
                placeholder="Password"
                data-test="password"
                value={password}
                onChange={this.handlePassChange}
                className="inputs"
              />
            </div>
            <div className="registerDivs">
              <input
                type="password"
                placeholder="Confirm password"
                data-test="confirm password"
                value={confirmPass}
                onChange={this.handleconfirmPassChange}
                className="inputs"
              />
            </div>
          </div>*/}

          <div className="message">
            <div className="messageContent">
              {error ? (
                <div
                  className="errorMessage"
                  data-test="error"
                  onClick={this.dismissError}
                >
                  {error}
                </div>
              ) : success ? (
                <div
                  className="successMessage"
                  data-test="success"
                  onClick={this.dismissSuccess}
                >
                  {success}
                </div>
              ) : (
                <div className="transparentMessage">.</div>
              )}
            </div>
            <div className="disclaimer">
              Volkswagen Autoeuropa, as controller, undertakes to use the data
              contained in the registration form exclusively for commercial
              purposes (business-to-business) related to supplier and
              partnership management processes and stock management that are in
              progress or will take place, not using them for other purposes,
              taking into account the legal requirement of necessity and
              minimization of the retention period. You may, at any time and
              free of charge, exercise your rights of access, rectification, or
              erasure of data, object, restriction, and portability of your data
              through the email address dpo@volkswagen.pt. For further
              information, please consult our{" "}
              <a className="privacyPolicy" href="privacyPolicy">
                Privacy Policy
              </a>{" "}
              or contact our Data Protection Officer at the email address
              mentioned above. If you believe that Volkswagen Autoeuropa may
              have infringed your rights under applicable data protection law,
              you may lodge a complaint with the Portuguese Supervisory
              Authority.
            </div>
            <input
              type="submit"
              value="Submit"
              data-test="submit"
              className="signBtn"
            />
            <div className="goToSignIn">
              Already registered?{" "}
              <a className="signInLink" href="login">
                Sign In
              </a>
            </div>
          </div>
        </form>
        <footer className="footerBar">
          <a className="footerLinksBlack" href="/privacyPolicy">
            Privacy policy
          </a>
        </footer>
      </div>
    );
  }
}
