import React from "react";
import { connect } from "react-redux";
import "./Invoices.css";
//import SearchTable from "./SearchTable";
//import Tabs from "./Tabs";
import { Clear, Search, GetApp } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat from "../../dateFormat";
import DatePicker, { DateObject } from "react-multi-date-picker";
import qs from "qs";

const mapStateToProps = (state) => {
    return {
        invoices: state.invoices,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showInvoices: (data) => dispatch({ type: "SHOW_INVOICES", payload: data }),
    };
};

class Invoices extends React.Component {
  state = {
    tab: 1,
    pending: false,
    currentTablePage: 0,
    currentItems: [],
    pages: 0,
    totalItems: 0,
    filter: "",
    week: new DateObject(),
  };

  logout() {
    const { history } = this.props;
    localStorage.removeItem("token");
    history.push("/");
  }

  handleChange = (tab) => {
    this.setState({ tab });
    const isSupplier = localStorage.getItem("isSupplier") === "true";
    switch (tab) {
      case 2:
        return isSupplier
          ? this.onNavigateProfile()
          : this.onNavigateBackoffice();
      case 0:
        return this.onNavigateStocks();
      default:
        return;
    }
  };

  handleChangeTablePage = async (page) => {
    const { filter, week } = this.state;
    this.setState({ currentTablePage: page });
    await this.fetchInvoices(page, filter, week);
  };

  handleFilter = (event) => {
    this.setState({
      filter: event.target.value,
    });
  };

  handleWeek = (value) => {
    this.setState({
      week: value,
    });
  };

  handleApplyFilter = async () => {
    const { filter, week } = this.state;
    this.setState({ currentTablePage: 0 });
    await this.fetchInvoices(0, filter, week);
  };

  handleExport = async () => {
    const { filter, week } = this.state;
    const { showInvoices } = this.props;
    this.setState({ pending: true });
    const isSupplier = localStorage.getItem("isSupplier") === "true";
    const dates = this.getDatesFromWeek(week);

    const headers = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    await fetch(
      process.env.REACT_APP_SERVER_LINK +
        this.getQueryString(isSupplier, 0, filter, dates, false, true),
      headers
    )
      .then((response) => response.json())
      .then((data) => {
        if (!!data.message) this.logout();
        const headers = isSupplier 
          ? ["Material number","Material description","Quantity","Invoice date"] 
          : ["Vendor number", "Material number","Material description","Quantity","Invoice date"]
        const array = data.reduce((acc, curr) => {
          if(!curr) return acc;
          const {inv_date, material_nr, material_desc, quantity} = curr;
          const date =
            !!curr.inv_date &&
            dateFormat.invertedFullDateWithTime(curr.inv_date);
          const current = isSupplier 
            ? [material_nr, material_desc, quantity, !!inv_date ? `${date.date} ${date.time}` : ""]
            : [curr.supplier_nr, material_nr, material_desc, quantity, !!inv_date ? `${date.date} ${date.time}` : ""]
          return [...acc, current];
        }, [headers]);
        let csvContent =
          "data:text/csv;charset=utf-8," +
          array.map((e) => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "invoices.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
      })
      .catch((e) => {
        console.err(e);
      })
      .finally(() => this.setState({ pending: false }));
  }

  handleCancelFilter = async () => {
    this.setState({ filter: "" });
  };

  handleCancelWeek = async () => {
    this.setState({ week: null });
  };

  enterSearch = async (event) => {
    if (event.keyCode === 13 && event.key === "Enter")
      await this.handleApplyFilter();
  };

  onNavigateStocks() {
    const { history } = this.props;
    history.push("/stocks");
  }

  onNavigateProfile() {
    const { history } = this.props;
    history.push("/profile");
  }

  onNavigateBackoffice() {
    const { history } = this.props;
    history.push("/backoffice");
  }

  onNavigateInvoices() {
    const { history } = this.props;
    history.push("/invoices");
  }

  verifyLogin() {
    return localStorage.getItem("token") == null;
  }

  getFilters = (filter, week) => {
    let query = {};
    if (!!filter) query = { ...query, filter };
    if (week)
      query = { ...query, startDate: week.firstDate, endDate: week.lastDate };
    return query;
  };

  getQueryString = (isSupplier, page, filter, week, count, exportation) => {
    let queryString = isSupplier ? "supplier/invoices" : "admin/invoices";
    const query = this.getFilters(filter, week);
    const search = !!query
      ? qs.stringify(query, {
          delimiter: ",",
          arrayFormat: "brackets",
        })
      : null;
    if (!!count) {
      queryString += "Count";
      if (this.props.match.params.id)
        queryString += "/" + this.props.match.params.id;
      if (!!search) queryString += `?search=${search}`;
      return queryString;
    }
    if (!!exportation) {
      queryString += "Exportation";
      if (!!search) queryString += `?search=${search}`;
      return queryString;
    }
    if (this.props.match.params.id)
      queryString += "/" + this.props.match.params.id;
    if (!!page || !!search) {
      queryString += "?";
      if (!!page || page === 0) queryString += `page=${page}`;
      if ((!!page || page === 0) && !!search) queryString += "&";
      if (!!search) queryString += `search=${search}`;
    }
    return queryString;
  };

  async fetchInvoices(page, filter, week) {
    const { showInvoices } = this.props;
    this.setState({ pending: true });
    const isSupplier = localStorage.getItem("isSupplier") === "true";
    const dates = this.getDatesFromWeek(week);

    const headers = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    await fetch(
      process.env.REACT_APP_SERVER_LINK +
        this.getQueryString(isSupplier, page, filter, dates, true),
      headers
    )
      .then((res) => res.json())
      .then((total) => {
        const totalRows = total[0] ? total[0].value : 10;
        fetch(
          process.env.REACT_APP_SERVER_LINK +
            this.getQueryString(isSupplier, page, filter, dates, false),
          headers
        )
          .then((response) => response.json())
          .then((data) => {
            if (!!data.message) this.logout();
            showInvoices(data);
            if (data.length !== 0) {
              const pages = Math.ceil(totalRows / 10);
              this.setState({
                pages,
                currentItems: data,
                totalItems: totalRows,
              });
            } else {
              this.setState({
                pages: 0,
                currentItems: [],
                totalItems: 0,
              });
            }
          })
          .catch((e) => {
            console.err(e);
          })
          .finally(() => this.setState({ pending: false }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async componentDidMount() {
    const { history } = this.props;
    const firstDayOfWeek = new DateObject().toFirstOfWeek();
    const lastDayOfWeek = new DateObject().toLastOfWeek();
    const week = [firstDayOfWeek, lastDayOfWeek];
    this.setState({
      week,
    });
    if (this.verifyLogin()) history.push("/");
    else await this.fetchInvoices(0, "", week);
  }

  getDatesFromWeek = (week) => {
    if (!week) return null;
    const firstDayOfWeek = week[0];
    const lastDayOfWeek = week[1];
    const firstDate = `${firstDayOfWeek.year}-${
      firstDayOfWeek.month.number < 10
        ? `0${firstDayOfWeek.month.number}`
        : firstDayOfWeek.month.number
    }-${
      firstDayOfWeek.day < 10 ? `0${firstDayOfWeek.day}` : firstDayOfWeek.day
    }`;
    const lastDate = `${lastDayOfWeek.year}-${
      lastDayOfWeek.month.number < 10
        ? `0${lastDayOfWeek.month.number}`
        : lastDayOfWeek.month.number
    }-${lastDayOfWeek.day < 10 ? `0${lastDayOfWeek.day}` : lastDayOfWeek.day}`;
    return { firstDate, lastDate };
  };

  getDot = (index, selected) => (
    <span
      className={selected ? "selectedDot" : "dot"}
      onClick={() => this.handleChangeTablePage(index)}
    >
      <span className="tooltip">{index + 1}</span>
    </span>
  );
  getSpaceDot = () => <span className="spaceDot" />;

  renderPoints(total, current) {
    const r = [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      total - 1,
      total - 2,
      total - 3,
      total - 4,
      total - 5,
      total - 6,
      total - 7,
      total - 8,
      total - 9,
      total - 10,
    ];
    for (let i = current - 40; i < current + 40; i++) {
      if (i > 0 && i < total - 5) r.push(i);
    }
    const uniqueArray = [...new Set(r.sort((a, b) => (a > b ? 1 : -1)))];
    const dots = [];
    for (let i = 0; i < uniqueArray.length; i++) {
      const value = uniqueArray[i];
      dots.push(this.getDot(value, value === current));
      if (i + 1 < uniqueArray.length && value + 1 !== uniqueArray[i + 1])
        dots.push(this.getSpaceDot());
    }
    return dots;
  }

  render() {
    const { pending, currentItems, currentTablePage, pages, filter, week } =
      this.state;
    const isSupplier = localStorage.getItem("isSupplier") === "true";
    return (
      <div className="Invoices" align="center">
        <div className="Invoice">
          <div className="logout">
            <div className="nameBlack">{localStorage.getItem("name")}</div>
            <button onClick={() => this.logout()} className="logoutBlackBtn">
              Sign out
              <img
                className="logoutBlackImg"
                alt="ok"
                width="12px"
                src={"./images/logout-black.svg"}
              />
            </button>
          </div>
          <div className="linhaLogo2">
            <div className="linha3" />
            <div className="imgLogo">
              <a href="/">
                <svg viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="#00204d"
                    d="M28 52.7C14.5 52.7 3.4 41.5 3.4 28c0-2.8.5-5.5 1.4-8.1l14.4 28c.2.4.4.7.9.7s.7-.3.9-.7L27.7 33c.1-.2.2-.3.3-.3.2 0 .2.2.3.3l6.8 14.9c.2.4.4.7.9.7s.7-.3.9-.7l14.4-28c.9 2.5 1.4 5.2 1.4 8.1-.1 13.5-11.2 24.7-24.7 24.7zm0-31.1c-.2 0-.2-.2-.3-.3L20 4.7c2.5-.9 5.2-1.4 8-1.4s5.5.5 8 1.4l-7.7 16.6c-.1.1-.1.3-.3.3zm-8.1 18c-.2 0-.2-.2-.3-.3L7 15.1c2.2-3.6 5.4-6.6 9.1-8.7L25 25.6c.2.4.4.5.7.5h4.6c.3 0 .5-.1.7-.5l8.8-19.2c3.7 2.1 6.9 5.1 9.1 8.7L36.4 39.3c-.1.2-.2.3-.3.3-.2 0-.2-.2-.3-.3L31 28.7c-.2-.4-.4-.5-.7-.5h-4.6c-.3 0-.5.1-.7.5l-4.8 10.6c-.1.1-.1.3-.3.3zM28 56c15.5 0 28-12.5 28-28S43.5 0 28 0 0 12.5 0 28s12.5 28 28 28z"
                  ></path>
                </svg>
              </a>
            </div>
            <div className="linha4" />
          </div>
        </div>
        {pending ? (
          <div className="progress">
            {/* AE fixes
              <CircularProgress />
              <div className="loadingMessage">Loading invoices...</div>
            */}
            A carregar
          </div>
        ) : (
          <>
            <div className="tabSearch">
              <div className="tabRow">
                <div className="tab" onClick={() => this.handleChange(0)}>
                  Stocks
                </div>
                <div className="tabSpace" />
                <div className="selectedTab">Invoices</div>
                <div className="tabSpace" />
                <div className="tab" onClick={() => this.handleChange(2)}>
                  {isSupplier ? "Profile" : "Backoffice"}
                </div>
              </div>
              <div className="searchColumn">
                <div className="searchItem">
                  <div className="searchName">Search</div>
                  <div className="searchRow">
                    <input
                      type="text"
                      className="searchBox"
                      name="search"
                      value={filter}
                      onChange={this.handleFilter}
                      onKeyDown={this.enterSearch}
                    />
                    {!!filter && (
                      <Clear
                        className="clearSearchIcon"
                        onClick={this.handleCancelFilter}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div className="searchName">Week</div>
                  <div className="searchRow">
                    <DatePicker
                      value={week}
                      onChange={this.handleWeek}
                      range
                      weekPicker
                      showOtherDays
                      displayWeekNumbers
                      weekNumber="WN"
                      format={"YYYY-MM-DD"}
                      maxDate={new DateObject()}
                      inputClass="dateInput"
                    />
                    {!!week && (
                      <Clear
                        className="clearSearchIcon"
                        onClick={this.handleCancelWeek}
                      />
                    )}
                  </div>
                </div>
                <Tooltip title="Filter">
                  <Search
                    className="searchIcon"
                    onClick={this.handleApplyFilter}
                  />
                </Tooltip>
                <Tooltip title="Export">
                  <GetApp className="exportIcon" onClick={this.handleExport} />
                </Tooltip>
              </div>
            </div>
            {/* AE fixes
            <Tabs
              isSupplier={isSupplier}
              current={tab}
              onChange={this.handleChange}
            />
            */}
            <div className="table">
              <table>
                <tr>
                  {!isSupplier && <th>Vendor number</th>}
                  <th>Material number</th>
                  <th>Material description</th>
                  <th>Quantity</th>
                  <th>Invoice date</th>
                </tr>
                {currentItems.length > 0 ? (
                  currentItems.map((curr) => {
                    const date =
                      !!curr.inv_date &&
                      dateFormat.invertedFullDateWithTime(curr.inv_date);
                    return [
                      <tr>
                        {!isSupplier && <td>{curr.supplier_nr}</td>}
                        <td>{curr.material_nr}</td>
                        <td>{curr.material_desc}</td>
                        <td>{curr.quantity}</td>
                        <td>
                          {!!curr.inv_date ? `${date.date} ${date.time}` : ""}
                        </td>
                      </tr>,
                    ];
                  })
                ) : (
                  <tr>
                    <div className="noRecords">
                      No records to display{" "}
                      {
                        //filter.indexOf("-") > -1 && "on this day"
                      }
                    </div>
                  </tr>
                )}
              </table>
            </div>
            <div className="footer">
              {pages < 100
                ? [...Array(pages)].map((curr, index) => [
                    this.getDot(index, currentTablePage === index),
                  ])
                : this.renderPoints(pages, currentTablePage)}
            </div>
          </>
        )}
        <footer className="footerBar">
          <a className="footerLinksBlack" href="/privacyPolicy">
            Privacy policy
          </a>
        </footer>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
