import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/app/App";
import { Provider } from "react-redux";
import store from "./store";

import LoginPage from "./components/login/LoginPage";
import Register from "./components/register/Register";
import Home from "./components/home/Home";
import Stocks from "./components/stocks/Stocks";
import Invoices from "./components/invoices/Invoices";
import Backoffice from "./components/infos/Backoffice";
import Profile from "./components/profile/Profile";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route exact path={"/"} component={App} />
        <Route path={"/login"} component={LoginPage} />
        <Route path={"/register"} component={Register} />
        <Route path={"/home"} component={Home} />
        <Route path={"/stocks/:id"} component={Stocks} />
        <Route path={"/stocks"} component={Stocks} />
        <Route path={"/invoices/:id"} component={Invoices} />
        <Route path={"/invoices"} component={Invoices} />
        <Route path={"/backoffice"} component={Backoffice} />
        <Route path={"/profile"} component={Profile} />
        <Route path={"/privacyPolicy"} component={PrivacyPolicy} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
