import React, { Component } from "react";
import "./PrivacyPolicy.css";
export default class PrivacyPolicy extends Component {
  constructor() {
    super();
  }

  render() {
    // NOTE: I use data-attributes for easier E2E testing
    // but you don't need to target those (any css-selector will work)
    //CHANGED PRIVACY POLICY FROM PT TO EN
    return (
      <div className="Register">
        <div className="linhaLogo2">
          <div className="linha3" />
          <div className="imgLogo">
            <a href="/">
              <svg viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#00204d"
                  d="M28 52.7C14.5 52.7 3.4 41.5 3.4 28c0-2.8.5-5.5 1.4-8.1l14.4 28c.2.4.4.7.9.7s.7-.3.9-.7L27.7 33c.1-.2.2-.3.3-.3.2 0 .2.2.3.3l6.8 14.9c.2.4.4.7.9.7s.7-.3.9-.7l14.4-28c.9 2.5 1.4 5.2 1.4 8.1-.1 13.5-11.2 24.7-24.7 24.7zm0-31.1c-.2 0-.2-.2-.3-.3L20 4.7c2.5-.9 5.2-1.4 8-1.4s5.5.5 8 1.4l-7.7 16.6c-.1.1-.1.3-.3.3zm-8.1 18c-.2 0-.2-.2-.3-.3L7 15.1c2.2-3.6 5.4-6.6 9.1-8.7L25 25.6c.2.4.4.5.7.5h4.6c.3 0 .5-.1.7-.5l8.8-19.2c3.7 2.1 6.9 5.1 9.1 8.7L36.4 39.3c-.1.2-.2.3-.3.3-.2 0-.2-.2-.3-.3L31 28.7c-.2-.4-.4-.5-.7-.5h-4.6c-.3 0-.5.1-.7.5l-4.8 10.6c-.1.1-.1.3-.3.3zM28 56c15.5 0 28-12.5 28-28S43.5 0 28 0 0 12.5 0 28s12.5 28 28 28z"
                ></path>
              </svg>
            </a>
          </div>
          <div className="linha4" />
        </div>
        <div className="privacyPolicyContent">
          <div className="privacyPolicyTitle">Privacy Policy</div>
          <div className="privacyPolicySubtitle">A. General Part</div>
          <p className="privacyPolicyText">
            This Privacy Policy intends to give to the Users of the websites
            hosted at supplynet.autoeuropa.pt (“Site”), detailed information on
            how Volkswagen  Autoeuropa, Lda., with head office at Quinta da
            Marquesa 2954-024 Quinta do Anjo, registered at the Palmela
            Commercial Registry Office, sob o with the corporate taxpayer number
            502 616 695, hereinafter “Volkswagen Autoeuropa” may require you to
            provide personal data, i.e. information provided by you that allows
            Volkswagen Autoeuropa to identify and/or contact you ("personal
            data").
          </p>
          <p className="privacyPolicyText">
            Through this Privacy Policy, Volkswagen Autoeuropa provides you,
            pursuant to your right to information set out in the General Data
            Protection Regulation (Regulation (EU) 2016/679, of the European
            Parliament and of the Council, of 27 April 2016) and the Personal
            Data Protection Act (Law No. 58/2019, 8 August), with detailed
            information about the nature of the data collected and about the
            purpose and processing that will be carried out in respect of your
            Personal Data.
          </p>
          <div className="privacyPolicyTextTitle">
            1. User data collection and processing
          </div>
          <p className="privacyPolicyText">
            Volkswagen Autoeuropa collects and processes the following user
            data, which applies depending on the purpose (see point 6 of this
            Policy):
          </p>
          <ul>
            <li className="privacyPolicyTextBullets">
              Identification data: name of contact person;
            </li>
            <li className="privacyPolicyTextBullets">
              Contact data: e-mail and telephone contact;
            </li>
            <li className="privacyPolicyTextBullets">
              Professional data: company and corporate tax number.
            </li>
          </ul>
          <div className="privacyPolicySubtitle">2. Communication of data</div>
          <p className="privacyPolicyText">
            The personal data collected is not shared with third parties without
            the user's consent, with the exception of the following situations:
          </p>
          <ul>
            <li className="privacyPolicyTextBullets">
              Communications required by law, in compliance with a certain legal
              obligation;
            </li>
            <li className="privacyPolicyTextBullets">
              Suppliers providing services as Subcontractors (see point 2.1. of
              this Privacy Policy).
            </li>
          </ul>
          <div className="privacyPolicyTextTitle">2.1 Processors</div>
          <p className="privacyPolicyText">
            As part of the processing of User Data, Volkswagen Autoeuropa uses
            or may use processors whose on its behalf, and in accordance with
            its instructions processes the User's Data, in accordance with the
            law and this Privacy Policy.
          </p>
          <p className="privacyPolicyText">
            These processors may not transmit the User Data to other entities
            without the prior written authorization.
          </p>
          <p className="privacyPolicyText">
            Volkswagen Autoeuropa undertakes to only subcontract the processors
            that have implemented the appropriate technical and organizational
            measures, to guarantee the defense of the User's rights. All
            entities sub-contracted by Volkswagen Autoeuropa shall be bound by
            Volkswagen Autoeuropa by means of a written agreement which covers:
            the object and duration of the processing, the nature and purpose of
            the processing, the type of personal data, the categories of data
            subjects and the rights and obligations of the parties.
          </p>
          <div className="privacyPolicySubtitle">
            3. General principles applicable to the processing of user data
          </div>
          <p className="privacyPolicyText">
            In terms of general principles regarding the processing of personal
            data, Volkswagen Autoeuropa undertakes to ensure that the User Data
            processed by it is:
          </p>
          <ul>
            <li className="privacyPolicyTextBullets">
              Subject to processing in accordance with the law, as well as being
              fair and transparent in relation to the User;
            </li>
            <li className="privacyPolicyTextBullets">
              Collected for specific purposes that are objective and legitimate,
              not being processed subsequently in any way that runs contrary to
              these purposes;
            </li>
            <li className="privacyPolicyTextBullets">
              Appropriate, justified and limited to what is necessary in
              relation to the purposes for which these data are processed;
            </li>
            <li className="privacyPolicyTextBullets">
              Accurate and updated whenever necessary with all necessary
              measures being taken to ensure that inaccurate data, considering
              the purposes for which they are processed, are erased, or
              corrected without delay;
            </li>
            <li className="privacyPolicyTextBullets">
              Kept in a manner that allows the identification of the User only
              for the period necessary for the purposes for which the data are
              to be processed;
            </li>
            <li className="privacyPolicyTextBullets">
              Handled in a manner that ensures data security, including
              protection against their unauthorized or illegal treatment and
              against their loss, destruction, or unforeseen damage, with
              appropriate technical or organizational measures being taken.
            </li>
          </ul>
          <div className="privacyPolicySubtitle">
            4. Lawfulness for processing personal data
          </div>
          <p className="privacyPolicyText">
            Data processing carried out by Volkswagen Autoeuropa is permitted
            and legitimate when at least one of the following situations occurs:
          </p>
          <ul>
            <li className="privacyPolicyTextBullets">
              The User has given his/her free, positive, explicit and
              unequivocal consent to the processing of his/her for one or more
              specific purposes;
            </li>
            <li className="privacyPolicyTextBullets">
              The processing is necessary for the implementation of a contract
              in which the User is a party, or for pre-contractual procedures at
              the request of the User;
            </li>
            <li className="privacyPolicyTextBullets">
              The processing is necessary for the fulfillment of a legal
              obligation to which Volkswagen Autoeuropa is subject;
            </li>
            <li className="privacyPolicyTextBullets">
              Processing is necessary for the defense of the vital interests of
              the User or another individual;
            </li>
            <li className="privacyPolicyTextBullets">
              The processing is necessary for legitimate interests pursued by
              Volkswagen Autoeuropa or by third parties (unless the interests or
              fundamental rights and freedoms of the User requiring the
              protection of personal data prevail).
            </li>
          </ul>
          <p className="privacyPolicyText">
            Volkswagen Autoeuropa undertakes to ensure that the processing of
            User Data is only done under the conditions cited above and
            respecting the principles mentioned above.
          </p>
          <p className="privacyPolicyText">
            When the processing of the User Data is performed by Volkswagen
            Autoeuropa is based on the User's consent, the User has the right to
            withdraw his consent at any time. Such withdrawal of the consent,
            however, does not jeopardize the legality of the processing carried
            out by Volkswagen Autoeuropa, based on the consent previously given
            by the User.
          </p>
          <div className="privacyPolicySubtitle">5. Data retention</div>
          <p className="privacyPolicyText">
            The length of time during which the data is filed and stored varies
            according to the purpose for which the information is being
            processed, being stored only for the necessary time for the
            fulfillment of the purposes for which they are processed. There are
            legal requirements that require the data to be preserved for a
            minimum period. Thus, and where there is no specific legal
            obligation, the data will be stored and kept only for the minimum
            period necessary for the purposes that led to their collection or
            subsequent processing, which at the end of the period will be
            eliminated.
          </p>
          <div className="privacyPolicySubtitle">
            6. Purposes of processing personal data
          </div>
          <p className="privacyPolicyText">
            In general, Volkswagen Autoeuropa uses the User Data for the
            following purposes:
          </p>
          <ul>
            <li className="privacyPolicyTextBullets">
              Management of partnerships with current and potential suppliers;
            </li>
            <li className="privacyPolicyTextBullets">
              Management of consignment stocks in Volkswagen Autoeuropa
              warehouses;
            </li>
            <li className="privacyPolicyTextBullets">
              User management (login and password creation).
            </li>
          </ul>
          <div className="privacyPolicySubtitle">
            7. Technical, organizational and security measures implemented
          </div>
          <p className="privacyPolicyText">
            In order to guarantee the security of the User Data and maximum
            confidentiality, Volkswagen Autoeuropa processes the information you
            provided to us in an absolutely confidential manner, in accordance
            with its internal security, and confidentiality policies and
            procedures, which are updated periodically as required, as well as
            the terms and conditions legally set out.
          </p>
          <p className="privacyPolicyText">
            As a function of the nature, scope, context and purpose of data
            processing, as well as the risks arising from the treatment of the
            rights and freedoms of the User, Volkswagen Autoeuropa undertakes to
            apply, both when defining the method and timing of handling the
            data, the technical and organizational measures necessary and
            appropriate for the protection of User Data and compliance with
            legal requirements.
          </p>
          <p className="privacyPolicyText">
            It also undertakes to ensure that, by default, only data that are
            necessary for each specific handling purpose are processed and that
            such data are not made available without human intervention to an
            indeterminate number of people.
          </p>
          <p className="privacyPolicyText">
            In general terms, Volkswagen Autoeuropa adopts the following:
          </p>
          <ul>
            <li className="privacyPolicyTextBullets">
              Regular audits to identify the effectiveness of the technical and
              organizational measures implemented;
            </li>
            <li className="privacyPolicyTextBullets">
              Training of personnel involved in data processing operations;
            </li>
            <li className="privacyPolicyTextBullets">
              Pseudonymization and coding of personal data;
            </li>
            <li className="privacyPolicyTextBullets">
              Mechanisms capable of ensuring the permanent confidentiality,
              availability and resilience of information systems;
            </li>
            <li className="privacyPolicyTextBullets">
              Mechanisms to ensure the restoration of information systems and
              access to personal data in a timely manner in the event of a
              physical or technical incident.
            </li>
          </ul>
          <div className="privacyPolicySubtitle">
            8. Transfer of data outside the European Union
          </div>
          <p className="privacyPolicyText">
            The personal data collected and used by Volkswagen Autoeuropa are
            not made available to third parties established outside the European
            Union. If, in the future, such a transfer takes place for the
            above-mentioned reasons, Volkswagen Autoeuropa undertakes to ensure
            that the transfer complies with the applicable legal provisions, in
            particular as regards determining the suitability of such country
            with regard to data protection and the requirements applicable to
            such transfers.
          </p>
          <div className="privacyPolicy2Subtitle">
            <div>B. User Data Protection rights</div>
            <div>9. Right of access</div>
          </div>
          <p className="privacyPolicyText">
            Volkswagen Autoeuropa guarantees the means that allow the user to
            consult his or her Personal Data. The User has the right to obtain
            confirmation from Volkswagen Autoeuropa that personal data
            concerning him or her are processed and, if applicable, the right to
            access his/her personal data. On request Volkswagen Autoeuropa will
            provide you, free of charge, with a copy of your data being
            processed. The provision of further copies upon request by you may
            incur administrative costs.
          </p>
          <div className="privacyPolicySubtitle">
            10. Right to rectification
          </div>
          <p className="privacyPolicyText">
            The User has the right to request, at any time, correction of his or
            her Personal Data, as well as the right to have incomplete personal
            data completed, including by means of an additional declaration.
          </p>
          <p className="privacyPolicyText">
            In the event of limitation of processing of data, Volkswagen
            Autoeuropa shall inform each recipient/entity to whom the data has
            been transmitted of the limitation, unless such communication proves
            impossible or involves a disproportionate effort on behalf of
            Volkswagen Autoeuropa.
          </p>
          <div className="privacyPolicySubtitle">
            11. Right to erasure ("right to be forgotten")
          </div>
          <p className="privacyPolicyText">
            You have the right to obtain, on the part of Volkswagen Autoeuropa,
            deletion of your data when one of the following reasons apply:
          </p>
          <ul>
            <li className="privacyPolicyTextBullets">
              The User Data is no longer required for the purpose for which it
              was collected or processed;
            </li>
            <li className="privacyPolicyTextBullets">
              The User withdraws the consent on which the data processing is
              based and there is no other legal basis for such processing;
            </li>
            <li className="privacyPolicyTextBullets">
              The User opposes the treatment under the right of opposition and
              there are no prevailing legitimate interests justifying the
              processing;
            </li>
            <li className="privacyPolicyTextBullets">
              If User Data is processed illegally;
            </li>
            <li className="privacyPolicyTextBullets">
              If User Data must be deleted in order to comply with a legal
              obligation to which Volkswagen Autoeuropa is subject.
            </li>
          </ul>
          <p className="privacyPolicyText">
            Under the applicable legal terms, Volkswagen Autoeuropa is under no
            obligation to delete User Data to the extent that the processing
            proves necessary to fulfill a legal obligation to which Volkswagen
            Autoeuropa is subject or for the purposes of declaring, exercising
            or defending Volkswagen Autoeuropa 's rights in judicial
            proceedings.
          </p>
          <p className="privacyPolicyText">
            In the event of the data being deleted, Volkswagen Autoeuropa shall
            inform each recipient/entity to whom the data has been transmitted
            of the deletion, unless such communication proves impossible or
            involves a disproportionate effort on behalf of
            Volkswagen Autoeuropa.
          </p>
          <p className="privacyPolicyText">
            When Volkswagen Autoeuropa has made the User Data public and is
            obliged to delete it under the right of such deletion,
            Volkswagen Autoeuropa undertakes to ensure reasonable measures,
            including of a technical nature, taking into account available
            technology and costs of its application to inform those responsible
            for the effective processing of personal data for which the User has
            requested deletion of the links to such personal data, as well as
            copies or reproductions thereof.
          </p>
          <div className="privacyPolicySubtitle">
            12. Right to restriction of processing
          </div>
          <p className="privacyPolicyText">
            The data subject shall have the right to obtain from the controller
            restriction of processing where one of the following applies:
          </p>
          <ul>
            <li className="privacyPolicyTextBullets">
              If the accuracy of personal data is challenged, for a period that
              allows Volkswagen Autoeuropa to verify its accuracy;
            </li>
            <li className="privacyPolicyTextBullets">
              If the processing is unlawful and the User opposes the deletion of
              the data, requesting, instead, a limitation on its use;
            </li>
            <li className="privacyPolicyTextBullets">
              If Volkswagen Autoeuropa no longer requires the User Data for
              processing purposes, but such data is required by the User for the
              purposes of declaring, exercising or defending their rights in
              judicial proceedings;
            </li>
            <li className="privacyPolicyTextBullets">
              In the event of the User objecting to the processing, until it is
              verified that Volkswagen Autoeuropa 's legitimate reasons prevail
              over those of the User.
            </li>
          </ul>
          <p className="privacyPolicyText">
            When User Data is subject to limitations, they may only, with the
            exception of storage, be processed with the consent of the User or
            for the purpose of declaring, exercising or defending a right in
            judicial proceedings, defending the rights of another natural or
            legal person, or for public interest reasons provided by law.
          </p>
          <p className="privacyPolicyText">
            The User who has obtained the limitation of the processing of their
            data in the above cases shall be informed by Volkswagen Autoeuropa
            before the limitation on processing is annulled.
          </p>
          <p className="privacyPolicyText">
            In the event of limitation of processing of data,
            Volkswagen Autoeuropa shall inform each recipient/entity to whom the
            data has been transmitted of the limitation, unless such
            communication proves impossible or involves a disproportionate
            effort on behalf of Volkswagen Autoeuropa.
          </p>
          <div className="privacyPolicySubtitle">
            13. Right of data portability
          </div>
          <p className="privacyPolicyText">
            The User has the right to receive personal data concerning him/her
            and which he/she has provided to Volkswagen Autoeuropa, in a
            structured, current and automated reading format, and the right to
            transmit such data to another person responsible for its processing,
            if:
          </p>
          <ul>
            <li className="privacyPolicyTextBullets">
              The processing is based on consent or a contract to which the User
              is a party; and
            </li>
            <li className="privacyPolicyTextBullets">
              The processing is performed by automated means.
            </li>
          </ul>
          <p className="privacyPolicyText">
            The right of portability does not include inferred or derived data,
            i.e. personal data that are generated by Volkswagen Autoeuropa
            because of, or resulting from, analysis of the data being processed.
            Users are entitled to have their personal data transmitted directly
            between those responsible for the processing, whenever this is
            technically possible.
          </p>
          <div className="privacyPolicySubtitle">14. Right to object</div>
          <p className="privacyPolicyText">
            Users have the right to object at any time to the processing of
            personal data based on the legitimate interests pursued by
            Volkswagen Autoeuropa or when the processing is carried out for
            purposes other than those for which personal data were collected,
            including the definition of profiles, or when personal data are
            processed for statistical purposes.
          </p>
          <p className="privacyPolicyText">
            Volkswagen Autoeuropa shall terminate the processing of User Data
            unless it can demonstrate urgent and legitimate reasons for such
            processing that prevail over the User's interests, rights and
            freedoms, or for the purposes of declaring, exercising or defending
            Volkswagen Autoeuropa 's rights in legal proceedings.
          </p>
          <p className="privacyPolicyText">
            When User Data is processed for the purpose of direct sales
            (marketing), Users have the right to oppose at any time the
            processing of the data that concern them for the purposes of said
            marketing, which includes the definition of profiles insofar as it
            relates to direct marketing. If Users object to the processing of
            their data for the purposes of direct marketing,
            Volkswagen Autoeuropa must cease the processing of the data for this
            purpose.
          </p>
          <p className="privacyPolicyText">
            Users also have the right not to be subject to any decision made
            solely on the basis of automated processing, including the
            definition of profiles, that has an effect in the legal sphere or
            has a significant similar effect, unless the decision:
          </p>
          <ul>
            <li className="privacyPolicyTextBullets">
              Is necessary for the signing or implementation of a contract
              between the User and Volkswagen Autoeuropa;
            </li>
            <li className="privacyPolicyTextBullets">
              Is authorized by legislation to which Volkswagen Autoeuropa is
              subject; or
            </li>
            <li className="privacyPolicyTextBullets">
              Is based on the explicit consent of the User.
            </li>
          </ul>
          <div className="privacyPolicySubtitle">
            15. Procedures for the exercising of rights by the user
          </div>
          <p className="privacyPolicyText">
            The right of access, right to rectification, right of elimination,
            right to restriction, right to data portability and right to object
            may be all exercised by the User by contacting the Data Protection
            Officer of Volkswagen Autoeuropa at  
            <a className="privacyPolicyEmail" href="mailto:dpo@volkswagen.pt">
              dpo@volkswagen.pt
            </a>
             or by written communication to Quinta da Marquesa, 2954-024 Quinta
            do Anjo.
          </p>
          <p className="privacyPolicyText">
            Volkswagen Autoeuropa will respond in writing (including by
            electronic means) to the User's request within a maximum period of
            one month from the receipt of the request, except in particularly
            complex cases, for which this period may be extended up to two
            months.
          </p>
          <p className="privacyPolicyText">
            If the requests submitted by the User are manifestly unjustified or
            excessive, especially due to their repetitive nature,
            Volkswagen Autoeuropa reserves the right to charge administrative
            costs or refuse to comply with the request.
          </p>
          <p className="privacyPolicyText">
            Please note that you have also the right to lodge a complaint before
            the competent supervisory authority — National Data Protection
            Committee, with its head office at Av. D. Carlos I, 134 - 1.º
            1200-651 Lisbon, with the following phone number (+351) 213928400
            and the following e-mail:
            <a className="privacyPolicyEmail" href="mailto:geral@cnpd.pt">
              geral@cnpd.pt
            </a>
            .
          </p>
          <div className="privacyPolicySubtitle">16. Personal data breach</div>
          <p className="privacyPolicyText">
            In the event of a personal data breach and insofar as such a breach
            is likely to entail a high risk to the User's rights and freedoms,
            Volkswagen Autoeuropa undertakes to inform the User, after the
            knowledge of such incident, without undue delay.
          </p>
          <p className="privacyPolicyText">
            Under the legislation, communication to the User is not required in
            the following cases:
          </p>
          <ul>
            <li className="privacyPolicyTextBullets">
              Where Volkswagen Autoeuropa has implemented appropriate protection
              measures, both technical and organisational, and those measures
              have been applied to the personal data affected by the personal
              data breach, especially measures rendering the personal data
              unintelligible to any person not authorised to access such data,
              such as encryption;
            </li>
            <li className="privacyPolicyTextBullets">
              When the personal data breach is not likely to result in a high
              risk to the rights and freedoms of natural persons; or
            </li>
            <li className="privacyPolicyTextBullets">
              If communication to the User implies a disproportionate effort on
              behalf of Volkswagen Autoeuropa. In this case, Volkswagen
              Autoeuropa will release a public communication or take a similar
              action by which the User will be informed.
            </li>
          </ul>
          <div className="privacyPolicy2Subtitle">
            <div>C. Final part</div>
            <div>17. Amendments to the privacy policy</div>
          </div>
          <p className="privacyPolicyText">
            Volkswagen Autoeuropa reserves the right to make changes to this
            Privacy Policy at any time. We advise you to read this document
            regularly.
          </p>
          <div className="privacyPolicySubtitle">
            18. Applicable law and jurisdiction
          </div>
          <p className="privacyPolicyText">
            The privacy policy, as well as the collection, processing or
            transmission of user data, are governed by the provisions of
            Regulation (EU) 2016/679, of the European Parliament and of the
            Council, of 27 April 2016 and by the legislation and regulations
            applicable in Portugal.
          </p>
          <p className="privacyPolicyText">
            Any disputes arising from the validity, interpretation or execution
            of the privacy policy, or which are related to the collection,
            processing or transmission of user data, shall be submitted
            exclusively to the jurisdiction of the judicial courts of the
            district of Setúbal, without prejudice to the applicable mandatory
            legal rules.
          </p>

          <p className="privacyPolicyDate">/September 2021</p>
        </div>
      </div>
    );
  }
}
