import React from "react";
import { connect } from "react-redux";
//import SearchTable from "./SearchTable";
//import Tabs from "./Tabs";
import "./Backoffice.css";
import { Edit, Clear, Check, Search, Euro} from "@material-ui/icons";
import dateFormat from "../../dateFormat";

const mapStateToProps = (state) => {
  return {
    infos: state.infos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showInfos: (data) => dispatch({ type: "SHOW_INFOS", payload: data }),
  };
};

class Backoffice extends React.Component {
  state = {
    tab: 2,
    pending: false,
    currentTablePage: 0,
    currentItems: [],
    pages: 0,
    totalItems: 0,
    editItem: null,
    filter: "",
  };

  logout() {
    const { history } = this.props;
    localStorage.removeItem("token");
    history.push("/");
  }

  handleChange = (tab) => {
    this.setState({ tab });
    switch (tab) {
      case 0:
        return this.onNavigateStocks();
      case 1:
        return this.onNavigateInvoices();
      default:
        return;
    }
  };

  handleChangeTablePage = async (page) => {
    const { filter } = this.state;
    this.setState({ currentTablePage: page });
    await this.fetchSuppliers(page, filter);
  };

  handleFilter = (event) => {
    this.setState({
      filter: event.target.value,
    });
  };

  handleApplyFilter = async () => {
    const { filter } = this.state;
    this.setState({ currentTablePage: 0 });

    await this.fetchSuppliers(0, filter);
  };

  handleCancelFilter = async () => {
    this.setState({ filter: "", currentTablePage: 0 });
    await this.fetchSuppliers(0);
  };

  enterSearch = async (event) => {
    if (event.keyCode === 13 && event.key === "Enter")
      await this.handleApplyFilter();
  };

  handleEdit = (item) => {
    this.setState({ editItem: { oldData: item, newData: item } });
  };

  handleCancelEdit = () => {
    const { editItem, currentItems } = this.state;
    const { oldData } = editItem;
    const updatedCurrentItems = currentItems.map((item) =>
      item.id === oldData.id ? oldData : item
    );
    this.setState({ editItem: null, currentItems: updatedCurrentItems });
  };

  handleCheckEdit = async () => {
    const { editItem, currentItems } = this.state;
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
        const { oldData, newData } = editItem;
        if (oldData) {
          const dataToUpdate = !!newData.password
            ? {
                vat: oldData.vat,
                login_date: oldData.login_date,
                number: newData.number,
                name: newData.name,
                email: newData.email,
                mobile: newData.mobile,
                contact_name: newData.contact_name,
                password: newData.password,
                state: newData.state ? 1 : 0,
              }
            : {
                vat: oldData.vat,
                login_date: oldData.login_date,
                number: newData.number,
                name: newData.name,
                email: newData.email,
                mobile: newData.mobile,
                contact_name: newData.contact_name,
                state: newData.state ? 1 : 0,
              };
          const updatedCurrentItems = currentItems.map((item) =>
            item.id === newData.id ? newData : item
          );
          fetch(process.env.REACT_APP_SERVER_LINK + "admin/update", {
            method: "POST",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToUpdate),
          }).then((response) => {
            this.setState({
              currentItems: updatedCurrentItems,
              editItem: null,
            });
            return response.json();
          });
          return { updatedCurrentItems };
        }
      }, 600);
    });
  };

  handleEditValue = (event) => {
    const { editItem, currentItems } = this.state;
    if (!event.target.name && !event.target.value) return;
    const updatedCurrentItems = currentItems.map((item) =>
      item.id === editItem.oldData.id
        ? { ...editItem.newData, [event.target.name]: event.target.value }
        : item
    );
    this.setState({
      editItem: {
        ...editItem,
        newData: {
          ...editItem.newData,
          [event.target.name]: event.target.value,
        },
      },
      currentItems: updatedCurrentItems,
    });
  };

  handleStateToggle = (event) => {
    const { editItem, currentItems } = this.state;
    if (!event.target.name && !event.target.value) return;
    const updatedCurrentItems = currentItems.map((item) =>
      item.id === editItem.oldData.id
        ? { ...editItem.newData, [event.target.name]: event.target.checked }
        : item
    );
    this.setState({
      editItem: {
        ...editItem,
        newData: {
          ...editItem.newData,
          [event.target.name]: event.target.checked,
        },
      },
      currentItems: updatedCurrentItems,
    });
  };

  handleRedirectToStock(id) {
    const { history } = this.props;
    history.push("/stocks/"+id);
  }

  handleRedirectToInvoice(id){
    const { history } = this.props;
    history.push("/invoices/"+id);
  }

  onNavigateStocks() {
    const { history } = this.props;
    history.push("/stocks");
  }

  onNavigateInvoices() {
    const { history } = this.props;
    history.push("/invoices");
  }

  onNavigateInfos() {
    const { history } = this.props;
    history.push("/backoffice");
  }

  verifyLogin() {
    return localStorage.getItem("token") == null;
  }

  getQueryString = (page, filter, count) => {
    let queryString = "admin/info";
    if (!!count) {
      queryString += "Count";
      if (!!filter) queryString += `?filter=${filter}`;
      return queryString;
    }
    if (!!page || !!filter) {
      queryString += "?";
      if (!!page || page === 0) queryString += `page=${page}`;
      if ((!!page || page === 0) && !!filter) queryString += "&";
      if (!!filter) queryString += `filter=${filter}`;
    }
    return queryString;
  };

  async fetchSuppliers(page, filter) {
    const { showInfos } = this.props;
    this.setState({ pending: true });
    const headers = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await fetch(
      process.env.REACT_APP_SERVER_LINK +
        this.getQueryString(page, filter, true),
      headers
    )
      .then((res) => res.json())
      .then((total) => {
        const totalRows = total[0] ? total[0].value : 10;
        fetch(
          process.env.REACT_APP_SERVER_LINK +
            this.getQueryString(page, filter, false),
          headers
        )
          .then((response) => response.json())
          .then((data) => {
            if (!!data.message) this.logout();
            showInfos(data);
            if (data.length !== 0) {
              const pages = Math.ceil(totalRows / 10);
              this.setState({
                pages,
                currentItems: data,
                totalItems: totalRows,
              });
            } else {
              this.setState({
                pages: 0,
                currentItems: [],
                totalItems: 0,
              });
            }
          })
          .catch((e) => {
            console.err(e);
          })
          .finally(() => this.setState({ pending: false }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async componentDidMount() {
    const { history } = this.props;
    if (this.verifyLogin()) history.push("/");
    else await this.fetchSuppliers(0);
  }

  getDot = (index, selected) => (
    <span
      className={selected ? "selectedDot" : "dot"}
      onClick={() => this.handleChangeTablePage(index)}
    >
      <span className="tooltip">{index + 1}</span>
    </span>
  );
  getSpaceDot = () => <span className="spaceDot" />;

  renderPoints(total, current) {
    const r = [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      total - 1,
      total - 2,
      total - 3,
      total - 4,
      total - 5,
      total - 6,
      total - 7,
      total - 8,
      total - 9,
      total - 10,
    ];
    for (let i = current - 40; i < current + 40; i++) {
      if (i > 0 && i < total - 5) r.push(i);
    }
    const uniqueArray = [...new Set(r.sort((a, b) => (a > b ? 1 : -1)))];
    const dots = [];
    for (let i = 0; i < uniqueArray.length; i++) {
      const value = uniqueArray[i];
      dots.push(this.getDot(value, value === current));
      if (i + 1 < uniqueArray.length && value + 1 !== uniqueArray[i + 1])
        dots.push(this.getSpaceDot());
    }
    return dots;
  }

  render() {
    const {
      //tab,
      pending,
      currentItems,
      editItem,
      currentTablePage,
      pages,
      filter,
    } = this.state;
    //const isSupplier = localStorage.getItem("isSupplier") === "true";

    return (
      <div className="Infos" align="center">
        <div className="Info">
          <div className="logout">
            <div className="nameBlack">{localStorage.getItem("name")}</div>
            <button onClick={() => this.logout()} className="logoutBlackBtn">
              Sign out
              <img
                className="logoutBlackImg"
                alt="ok"
                width="12px"
                src={"./images/logout-black.svg"}
              />
            </button>
          </div>
          <div className="linhaLogo2">
            <div className="linha3" />
            <div className="imgLogo">
              <a href="/">
                <svg viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="#00204d"
                    d="M28 52.7C14.5 52.7 3.4 41.5 3.4 28c0-2.8.5-5.5 1.4-8.1l14.4 28c.2.4.4.7.9.7s.7-.3.9-.7L27.7 33c.1-.2.2-.3.3-.3.2 0 .2.2.3.3l6.8 14.9c.2.4.4.7.9.7s.7-.3.9-.7l14.4-28c.9 2.5 1.4 5.2 1.4 8.1-.1 13.5-11.2 24.7-24.7 24.7zm0-31.1c-.2 0-.2-.2-.3-.3L20 4.7c2.5-.9 5.2-1.4 8-1.4s5.5.5 8 1.4l-7.7 16.6c-.1.1-.1.3-.3.3zm-8.1 18c-.2 0-.2-.2-.3-.3L7 15.1c2.2-3.6 5.4-6.6 9.1-8.7L25 25.6c.2.4.4.5.7.5h4.6c.3 0 .5-.1.7-.5l8.8-19.2c3.7 2.1 6.9 5.1 9.1 8.7L36.4 39.3c-.1.2-.2.3-.3.3-.2 0-.2-.2-.3-.3L31 28.7c-.2-.4-.4-.5-.7-.5h-4.6c-.3 0-.5.1-.7.5l-4.8 10.6c-.1.1-.1.3-.3.3zM28 56c15.5 0 28-12.5 28-28S43.5 0 28 0 0 12.5 0 28s12.5 28 28 28z"
                  ></path>
                </svg>
              </a>
            </div>
            <div className="linha4" />
          </div>
        </div>
        {pending ? (
          <div className="progress">
            {/* AE fixes
              <CircularProgress />
              <div className="loadingMessage">Loading suppliers...</div>
            */}
            A carregar
          </div>
        ) : (
          <>
            <div className="tabSearch">
              <div className="tabRow">
                <div className="tab" onClick={() => this.handleChange(0)}>
                  Stocks
                </div>
                <div className="tabSpace" />
                <div className="tab" onClick={() => this.handleChange(1)}>
                  Invoices
                </div>
                <div className="tabSpace" />
                <div className="selectedTab"> Backoffice </div>
              </div>
              <div className="searchBackofficeColumn">
                <div className="searchName">Search</div>
                <div className="searchRow">
                  <Search
                    className="searchBackofficeIcon"
                    onClick={this.handleApplyFilter}
                  />
                  <input
                    type="text"
                    className="searchBackofficeBox"
                    name="search"
                    value={filter}
                    onChange={this.handleFilter}
                    onKeyDown={this.enterSearch}
                  />
                  {!!filter && (
                    <Clear
                      className="clearSearchBackofficeIcon"
                      onClick={this.handleCancelFilter}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* AE fixes
            <Tabs
              isSupplier={isSupplier}
              current={tab}
              onChange={this.handleChange}
            />
           
            <SearchTable className="table" infos={infos} /> */}
            <div className="table">
              <table>
                <tr>
                  <th>Actions</th>
                  <th>Vendor Number</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Contact name</th>
                  <th>VAT</th>
                  <th>Status</th>
                  <th>Login Date</th>
                  <th>Password</th>
                  <th>Invoices</th>
                </tr>
                {currentItems.length > 0 ? (
                  currentItems.map((curr) => {
                    const date =
                      !!curr.login_date &&
                      dateFormat.invertedFullDateWithTime(curr.login_date);
                    if (
                      !!editItem &&
                      !!editItem.oldData &&
                      curr.id === editItem.oldData.id
                    ) {
                      return [
                        <tr>
                          <td>
                            <Check
                              className="editIcon"
                              onClick={this.handleCheckEdit}
                            />
                            <Clear
                              className="editIcon"
                              onClick={this.handleCancelEdit}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="editInput"
                              name="number"
                              value={curr.number}
                              onChange={this.handleEditValue}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="editInput"
                              name="name"
                              value={curr.name}
                              onChange={this.handleEditValue}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="editInput"
                              name="email"
                              value={curr.email}
                              onChange={this.handleEditValue}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="editInput"
                              name="mobile"
                              value={curr.mobile}
                              onChange={this.handleEditValue}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="editInput"
                              name="contact_name"
                              value={curr.contact_name}
                              onChange={this.handleEditValue}
                            />
                          </td>
                          <td>{curr.vat}</td>
                          <td>
                            {!!curr.state ? (
                              <div onClick={this.handleStateToggle}>
                                <input
                                  type="checkbox"
                                  id="switch"
                                  class="checkbox"
                                  name="state"
                                  checked
                                />
                                <label
                                  for="switch"
                                  class="toggle"
                                  name="state"
                                />
                              </div>
                            ) : (
                              <div onClick={this.handleStateToggle}>
                                <input
                                  type="checkbox"
                                  id="switch"
                                  class="checkbox"
                                  name="state"
                                />
                                <label
                                  for="switch"
                                  class="toggle"
                                  name="state"
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            {!!curr.login_date
                              ? `${date.date} ${date.time}`
                              : ""}
                          </td>
                          <td>
                            <input
                              type="password"
                              className="editInput"
                              name="password"
                              onChange={this.handleEditValue}
                            />
                          </td>
                          <td>
                            <Euro
                              className="editIcon"
                              onClick={() =>
                                this.handleRedirectToInvoice(curr.number)
                              }
                            />
                          </td>
                          {
                            //Inserir td com icon para invoices com vendor number
                          }
                        </tr>,
                      ];
                    }
                    return [
                      <tr>
                        <td>
                          <Edit
                            className="editIcon"
                            onClick={() => this.handleEdit(curr)}
                          />
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.handleRedirectToStock(curr.number)
                          }
                        >
                          {curr.number}
                        </td>
                        <td>{curr.name}</td>
                        <td>{curr.email}</td>
                        <td>{curr.mobile}</td>
                        <td>{curr.contact_name}</td>
                        <td>{curr.vat}</td>
                        <td>{!!curr.state ? "Active" : "Inactive"}</td>
                        <td>
                          {!!curr.login_date ? `${date.date} ${date.time}` : ""}
                        </td>
                        <td>******</td>
                        <td>
                          <Euro
                            className="editIcon"
                            onClick={() =>
                              this.handleRedirectToInvoice(curr.number)
                            }
                          />
                        </td>
                      </tr>,
                    ];
                  })
                ) : (
                  <tr>
                    <div className="noRecords">No records to display</div>
                  </tr>
                )}
              </table>
            </div>
            <div className="footer">
              {pages < 100
                ? [...Array(pages)].map((curr, index) => [
                    this.getDot(index, currentTablePage === index),
                  ])
                : this.renderPoints(pages, currentTablePage)}
            </div>
          </>
        )}
        <footer className="footerBar">
          <a className="footerLinksBlack" href="/privacyPolicy">
            Privacy policy
          </a>
        </footer>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Backoffice);
