import React, { Component } from "react";
import "../app/App.css";
import "./LoginPage.css";
import { withRouter } from "react-router-dom";

class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      vat: "",
      password: "",
      error: "",
      token: "",
    };

    this.handlePassChange = this.handlePassChange.bind(this);
    this.handleVatChange = this.handleVatChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissError = this.dismissError.bind(this);
    this.onNavigateHome = this.onNavigateHome.bind(this);
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token") !== null) {
      const { history } = this.props;
      history.push("/home");
    }
  }

  dismissError() {
    this.setState({ error: "" });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    const { vat, password } = this.state;

    if (!vat) {
      return this.setState({ error: "VAT is required" });
    }

    if (!password) {
      return this.setState({ error: "Password is required" });
    }

    return this.setState({ error: "" });
  }

  handleVatChange(evt) {
    this.setState({
      vat: evt.target.value,
    });
  }

  handlePassChange(evt) {
    this.setState({
      password: evt.target.value,
    });
  }

  onNavigateHome() {
    if (localStorage.getItem("token") != null) {
      const { history } = this.props;
      history.push("/home");
    } else {
      this.setState({
        password: "",
      });
    }
  }

  async login(vat, password) {
    let errorAuth = null;
    await fetch(process.env.REACT_APP_SERVER_LINK + "user/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        vat: vat,
        password: password,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401 || response.status === 400)
            this.setState({ error: "Incorrect VAT or password." });
          else if (response.status === 403)
            this.setState({
              error: "This account is inactive. Contact admin.",
            });
          else if (response.status === 405)
            this.setState({
              error:
                "This account is not approved yet. Waiting for admin approval.",
            });
          else
            this.setState({
              error: "Authentication failed.",
            });
        }
        return response.json();
      })
      .then(function (data) {
        const token = data.token;
        const name = data.name;
        const isSupplier = data.isSupplier;
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("isSupplier");
        if (token != null) {
          localStorage.setItem("token", token);
        }
        if (name != null) {
          localStorage.setItem("name", name);
        }
        if (isSupplier != null) {
          localStorage.setItem("isSupplier", isSupplier);
        }
        if (data.message === "Auth failed") {
          errorAuth = true;
        }
      })
      .catch();
    if (errorAuth) {
      document.getElementById("password").value = "";
    } else {
      this.onNavigateHome();
    }
  }

  render() {
    // NOTE: I use data-attributes for easier E2E testing
    // but you don't need to target those (any css-selector will work)
    const { vat, password, error } = this.state;
    return (
      <div className="Login">
        <div className="linhaLogo2">
          <div className="linha3" />
          <div className="imgLogo">
            <a href="/">
              <svg viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#00204d"
                  d="M28 52.7C14.5 52.7 3.4 41.5 3.4 28c0-2.8.5-5.5 1.4-8.1l14.4 28c.2.4.4.7.9.7s.7-.3.9-.7L27.7 33c.1-.2.2-.3.3-.3.2 0 .2.2.3.3l6.8 14.9c.2.4.4.7.9.7s.7-.3.9-.7l14.4-28c.9 2.5 1.4 5.2 1.4 8.1-.1 13.5-11.2 24.7-24.7 24.7zm0-31.1c-.2 0-.2-.2-.3-.3L20 4.7c2.5-.9 5.2-1.4 8-1.4s5.5.5 8 1.4l-7.7 16.6c-.1.1-.1.3-.3.3zm-8.1 18c-.2 0-.2-.2-.3-.3L7 15.1c2.2-3.6 5.4-6.6 9.1-8.7L25 25.6c.2.4.4.5.7.5h4.6c.3 0 .5-.1.7-.5l8.8-19.2c3.7 2.1 6.9 5.1 9.1 8.7L36.4 39.3c-.1.2-.2.3-.3.3-.2 0-.2-.2-.3-.3L31 28.7c-.2-.4-.4-.5-.7-.5h-4.6c-.3 0-.5.1-.7.5l-4.8 10.6c-.1.1-.1.3-.3.3zM28 56c15.5 0 28-12.5 28-28S43.5 0 28 0 0 12.5 0 28s12.5 28 28 28z"
                ></path>
              </svg>
            </a>
          </div>
          <div className="linha4" />
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="title">
            <div className="title1"> Welcome </div>
          </div>
          <div className="info3">
            <input
              type="text"
              placeholder="VAT"
              data-test="vat"
              value={vat}
              onChange={this.handleVatChange}
              className="inputs"
            />
            <input
              type="password"
              id="password"
              placeholder="Password"
              data-test="password"
              value={password}
              onChange={this.handlePassChange}
              className="inputs"
            />
          </div>
          <div className="message">
            {error && (
              <div
                className="errorMessage"
                data-test="error"
                onClick={this.dismissError}
              >
                {error}
              </div>
            )}
            <input
              type="submit"
              value="Submit"
              onClick={() => {
                this.login(vat, password);
              }}
              data-test="submit"
              className="signBtn"
            />
            <div className="goToSignIn">
              Not registered?{" "}
              <a className="signInLink" href="register">
                Create an account
              </a>
            </div>
          </div>
        </form>
        <footer className="footerBar">
          <a className="footerLinksBlack" href="/privacyPolicy">
            Privacy policy
          </a>
        </footer>
      </div>
    );
  }
}

export default withRouter(LoginPage);
