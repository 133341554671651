const invoiceReducer = (state = {
    invoices: []
}, action) => {
    switch (action.type) {
        case "SHOW_INVOICES":
            return state = {
                ...state,
                invoices: action.payload
            };
        default:
            return state;
    }
};

export default invoiceReducer;
