const infoReducer = (state = {
    infos: []
}, action) => {
    switch (action.type) {
        case "SHOW_INFOS":
            return state = {
                ...state,
                infos: action.payload
            };
        default:
            return state;
    }
};

export default infoReducer;
