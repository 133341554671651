import React, { Component } from "react";
import "./Profile.css";
//import Tabs from "./Tabs";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    infos: state.infos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showInfos: (data) => dispatch({ type: "SHOW_INFOS", payload: data }),
  };
};

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      error: "",
      contact_name: "",
      success: "",
      country: "",
      vat: "",
      sap: "",
      profileFields: {
        companyname: "",
        email: "",
        contact_name: "",
        mobile: "",
      },
      passwordFields: {
        password: "",
        confirmPassword: "",
      },
      changed: false,
      changePassword: false,
      tab: 2,
    };

    this.dismissError = this.dismissError.bind(this);
    this.dismissSuccess = this.dismissSuccess.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangePasswordInput = this.handleChangePasswordInput.bind(this);
    this.save = this.save.bind(this);
    this.savePassword = this.savePassword.bind(this);
  }

  async componentDidMount() {
    const { history, showInfos } = this.props;
    if (this.verifyLogin()) {
      history.push("/");
    } else {
      await fetch(process.env.REACT_APP_SERVER_LINK + "supplier/info", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          showInfos(data);
        })
        .catch();

      this.handleChange();
    }
  }

  handleChangeTab = (tab) => {
    this.setState({ tab });
    switch (tab) {
      case 0:
        return this.onNavigateStocks();
      case 1:
        return this.onNavigateInvoices();
      default:
        return;
    }
  };

  onNavigateStocks() {
    const { history } = this.props;
    history.push("/stocks");
  }

  onNavigateInvoices() {
    const { history } = this.props;
    history.push("/invoices");
  }

  onNavigateInfos() {
    const { history } = this.props;
    history.push("/profile");
  }

  verifyLogin() {
    return localStorage.getItem("token") == null;
  }

  dismissError() {
    this.setState({ error: "" });
  }

  dismissSuccess() {
    this.setState({ success: "" });
  }

  save() {
    const { profileFields } = this.state;
    const { companyname, email, contact_name, mobile } = profileFields;
    fetch(process.env.REACT_APP_SERVER_LINK + "supplier/update", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email,
        name: companyname,
        mobile,
        contact_name,
      }),
    }).then((response) => {
      if (response.ok)
        this.setState({
          success: "Information changed successfully!",
          changed: false,
        });
      else
        this.setState({
          error: "Something must be wrong. Please again try later.",
          changed: false,
        });
      return response.json();
    });
  }

  savePassword() {
    const { passwordFields, vat } = this.state;
    const { password, confirmPassword } = passwordFields;
    if (password.length < 8)
      this.setState({
        error: "Password too short. Must have at least 8 characters.",
      });
    else if (password !== confirmPassword)
      this.setState({ error: "Passwords aren't matching" });
    else {
      fetch(process.env.REACT_APP_SERVER_LINK + "supplier/update", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          vat,
          password,
        }),
      }).then((response) => {
        if (response.ok)
          this.setState({
            success: "Password changed successfully!",
            changed: false,
            changePassword: false,
            passwordFields: {
              password: "",
              confirmPassword: "",
            },
          });
        else
          this.setState({
            error: "Something must be wrong. Please try again later.",
            changed: false,
          });
        return response.json();
      });
    }
  }

  handleChange() {
    const { infos } = this.props;
    if (!infos || !infos.infos || !infos.infos[0]) return;
    const { name, email, vat, contact_name, mobile, number } = infos.infos[0];
    this.setState({
      vat,
      sap: number,
      profileFields: {
        companyname: name,
        email,
        contact_name,
        mobile,
      },
    });
  }

  handleChangeInput(event) {
    const { profileFields } = this.state;
    this.setState({
      profileFields: {
        ...profileFields,
        [event.target.id]: event.target.value,
      },
      changed: true,
      error: null,
      success: null,
    });
  }

  handleChangePasswordInput(event) {
    const { passwordFields } = this.state;
    this.setState({
      passwordFields: {
        ...passwordFields,
        [event.target.id]: event.target.value,
      },
      changed: true,
    });
  }

  handleChangePassword = () => {
    const { changePassword } = this.state;
    this.setState(
      {
        changePassword: !changePassword,
        changed: false,
        error: null,
        success: null,
      },
      () => this.handleChange()
    );
  };

  logout() {
    const { history } = this.props;
    localStorage.removeItem("token");
    history.push("/");
  }

  render() {
    // NOTE: I use data-attributes for easier E2E testing
    // but you don't need to target those (any css-selector will work)
    /* 
                    }*/
    //const isSupplier = localStorage.getItem("isSupplier") === "true";
    const {
      profileFields,
      changed,
      //tab,
      changePassword,
      passwordFields,
      vat,
      sap,
      error,
      success,
    } = this.state;

    return (
      <div className="Profiles" align="center">
        <div className="Profile">
          <div className="logout">
            <div className="nameBlack">{localStorage.getItem("name")}</div>
            <button onClick={() => this.logout()} className="logoutBlackBtn">
              Sign out
              <img
                className="logoutBlackImg"
                alt="ok"
                width="12px"
                src={"./images/logout-black.svg"}
              />
            </button>
          </div>
          <div className="linhaLogo2">
            <div className="linha3" />
            <div className="imgLogo">
              <a href="/">
                <svg viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="#00204d"
                    d="M28 52.7C14.5 52.7 3.4 41.5 3.4 28c0-2.8.5-5.5 1.4-8.1l14.4 28c.2.4.4.7.9.7s.7-.3.9-.7L27.7 33c.1-.2.2-.3.3-.3.2 0 .2.2.3.3l6.8 14.9c.2.4.4.7.9.7s.7-.3.9-.7l14.4-28c.9 2.5 1.4 5.2 1.4 8.1-.1 13.5-11.2 24.7-24.7 24.7zm0-31.1c-.2 0-.2-.2-.3-.3L20 4.7c2.5-.9 5.2-1.4 8-1.4s5.5.5 8 1.4l-7.7 16.6c-.1.1-.1.3-.3.3zm-8.1 18c-.2 0-.2-.2-.3-.3L7 15.1c2.2-3.6 5.4-6.6 9.1-8.7L25 25.6c.2.4.4.5.7.5h4.6c.3 0 .5-.1.7-.5l8.8-19.2c3.7 2.1 6.9 5.1 9.1 8.7L36.4 39.3c-.1.2-.2.3-.3.3-.2 0-.2-.2-.3-.3L31 28.7c-.2-.4-.4-.5-.7-.5h-4.6c-.3 0-.5.1-.7.5l-4.8 10.6c-.1.1-.1.3-.3.3zM28 56c15.5 0 28-12.5 28-28S43.5 0 28 0 0 12.5 0 28s12.5 28 28 28z"
                  ></path>
                </svg>
              </a>
            </div>
            <div className="linha4" />
          </div>

          <div className="tabPadding">
            <div className="tabRow">
              <div className="tab" onClick={() => this.handleChangeTab(0)}>
                Stocks
              </div>
              <div className="tabSpace" />
              <div className="tab" onClick={() => this.handleChangeTab(1)}>
                Invoices
              </div>
              <div className="tabSpace" />
              <div className="selectedTab"> Profile </div>
            </div>
            {/* AE fixes
            <Tabs
              isSupplier={isSupplier}
              current={tab}
              onChange={this.handleChangeTab}
            />
            */}
          </div>
          <form onSubmit={this.handleSubmit}>
            {changePassword ? (
              <>
                <div className="Info3">
                  <label htmlFor="password">Password</label>

                  <input
                    type="password"
                    id="password"
                    name="password"
                    data-test="password"
                    value={passwordFields.password}
                    onChange={this.handleChangePasswordInput}
                    className="passwords"
                  />
                </div>
                <div className="Info3">
                  <label id="confirmPassword" htmlFor="confirmPassword">
                    Confirm password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    data-test="confirmPassword"
                    value={passwordFields.confirmPassword}
                    onChange={this.handleChangePasswordInput}
                    className="passwords"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="Info3">
                  <label htmlFor="companyname">Company Name</label>

                  <input
                    type="text"
                    id="companyname"
                    name="companyname"
                    data-test="company name"
                    value={profileFields.companyname}
                    onChange={this.handleChangeInput}
                  />
                </div>

                <div className="Info3">
                  <label id="contactlabel" htmlFor="contact_name">
                    Contact name
                  </label>
                  <input
                    type="text"
                    id="contact_name"
                    name="contact_name"
                    data-test="name"
                    value={profileFields.contact_name}
                    onChange={this.handleChangeInput}
                  />
                </div>

                <div className="Info3">
                  <label id="mobilenumber" htmlFor="mobile">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    id="mobile"
                    name="mobile"
                    data-test="mobile"
                    value={profileFields.mobile}
                    onChange={this.handleChangeInput}
                  />
                </div>
                <div className="Info3">
                  <label id="emailLabel" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    data-test="email"
                    value={profileFields.email}
                    onChange={this.handleChangeInput}
                  />
                </div>
                <div className="Info3">
                  <label id="sapnumber" htmlFor="sap">
                    Vendor Number
                  </label>
                  <input
                    type="text"
                    id="sap"
                    name="sap"
                    data-test="sap"
                    value={sap}
                    readOnly="readonly"
                  />
                </div>
                <div className="Info3">
                  <label id="vatlabel" htmlFor="VAT">
                    VAT
                  </label>
                  <input
                    type="text"
                    id="vat"
                    name="vat"
                    data-test="vat"
                    value={vat}
                    readOnly="readonly"
                  />
                </div>
              </>
            )}
            <div className="message">
              {error && (
                <div
                  className="errorMessage"
                  data-test="error"
                  onClick={this.dismissError}
                >
                  {error}
                </div>
              )}
              {success && (
                <div
                  className="successMessage"
                  data-test="success"
                  onClick={this.dismissSuccess}
                >
                  {success}
                </div>
              )}
            </div>

            <div className="profileButtons">
              <button
                type="button"
                className="btn2"
                onClick={() => this.handleChangePassword()}
              >
                {changePassword ? "Cancel" : "Change password"}
              </button>

              <button
                type="button"
                className={changed ? "btn2" : "disabledBtn"}
                disabled={!changed}
                onClick={() =>
                  changePassword ? this.savePassword() : this.save()
                }
              >
                Save
              </button>
            </div>
          </form>
          <footer className="footerBar">
            <a className="footerLinksBlack" href="/privacyPolicy">
              Privacy policy
            </a>
          </footer>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
