const monthsNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default {
  readableDate: (dateString: string) => {
    const date = new Date(dateString);
    return `${
      monthsNames[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  },
  readableDateTime: (dateString: string) => {
    const date = new Date(dateString);
    return `${
      monthsNames[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()} at ${date.getHours()}:${date.getMinutes()}`;
  },
  fullDate: (dateString: string) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day}/${monthIndex + 1}/${year}`;
  },
  invertedFullDate: (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const completeDay = day.toString().length > 1 ? `${day}` : `0${day}`;
    const monthIndex = date.getMonth();
    const monthDisplay = monthIndex + 1;
    const completeMonth =
      monthDisplay.toString().length > 1
        ? `${monthDisplay}`
        : `0${monthDisplay}`;
    const year = date.getFullYear();
    return `${year}-${completeMonth}-${completeDay}`;
  },
  fullDateWithTime: (dateString: string) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const completeDay = day.toString().length > 1 ? `${day}` : `0${day}`;
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const completeHours =
      hours.toString().length > 1 ? `${hours}` : `0${hours}`;
    const minutes = date.getMinutes();
    const completeMinutes =
      minutes.toString().length > 1 ? `${minutes}` : `0${minutes}`;

    return {
      date: `${completeDay}/${monthIndex + 1}/${year}`,
      time: `${completeHours}:${completeMinutes}`,
    };
  },
  invertedFullDateWithTime: (dateString: string) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const completeDay = day.toString().length > 1 ? `${day}` : `0${day}`;
    const monthIndex = date.getMonth();
    const monthDisplay = monthIndex + 1;
    const completeMonth =
      monthDisplay.toString().length > 1
        ? `${monthDisplay}`
        : `0${monthDisplay}`;
    const year = date.getFullYear();
    const hours = date.getHours();
    const completeHours =
      hours.toString().length > 1 ? `${hours}` : `0${hours}`;
    const minutes = date.getMinutes();
    const completeMinutes =
      minutes.toString().length > 1 ? `${minutes}` : `0${minutes}`;

    return {
      date: `${year}-${completeMonth}-${completeDay}`,
      time: `${completeHours}:${completeMinutes}`,
    };
  },
};
