import {createStore, combineReducers} from 'redux';

import stocks from "./reducers/stockReducer";
import invoices from "./reducers/invoiceReducer";
import infos from "./reducers/infoReducer";

const store = createStore(
    combineReducers({
        stocks, invoices, infos
    }), 
    {}
);

export default store;